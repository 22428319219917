<template>
    <div class="main">
        <div class="search">
            <div class="refresh" @click="getJiliGamesList"><i class="fa fa-refresh"></i></div>
            <el-select v-model="search.categroyid" @change="getJiliGamesList" size="mini"
                       placeholder="카테고리"
                       style="width:100px;margin-left: 5px">
                <el-option key="17" label="전체" :value="null">전체</el-option>
                <el-option key="18" label="Slot" :value="managerConst.JiliGame.CATEGROY_ELECTRONIC_GAME">Slot</el-option>
                <el-option key="19" label="Poker" :value="managerConst.JiliGame.CATEGROY_CARD_GAME">Poker</el-option>
                <el-option key="21" label="Fishing" :value="managerConst.JiliGame.CATEGROY_FISHING_GAME">Fishing</el-option>
                <el-option key="22" label="Casino" :value="managerConst.JiliGame.CATEGROY_BET_EXTENSION">Casino (including bingo)</el-option>
            </el-select>
        </div>
        <div class="data">
            <div class="jiligames-item" v-for="game in gameList" style="">
                <div>
                    <img :src="game.imageUrlTemp"
                         style="max-width:200px;vertical-align: middle">
                </div>
                <div>
                    <div style="margin-top: 3px">
                        제목
                    </div><el-input v-model="game.enname" size="mini"
                              style="width: 90%"></el-input>
                </div>
                <div>
                    <div style="margin-top: 3px">정열순위</div><el-input v-model="game.sort" size="mini"
                              style="width: 90%"></el-input>
                </div>
                <div>
                    <div style="margin-top: 3px">인기여부</div>
                    <el-select size="mini" style="width: 90%" v-model="game.popular"
                               placeholder="예">
                        <el-option :value="managerConst.YES" label="예">예</el-option>
                        <el-option :value="managerConst.NOT"
                                   label="아니오">아니오
                        </el-option>
                    </el-select>
                </div>
                <div style="padding-top: 5px">
                    <el-upload
                            ref="upload"
                            class="upload-demo"
                            action="#"
                            :http-request="httpRequest"
                            :before-upload="beforeUpload"
                            :on-change="fileChanged"
                            :file-list="fileList"
                            :show-file-list="false"
                    >
                        <el-button slot="trigger" size="small" type="primary">이미지선택
                        </el-button>
                        <el-button
                                style="margin-left: 10px"
                                size="small"
                                type="success"
                                @click="submitUpload(game)"
                        >이미지등록
                        </el-button>

                    </el-upload>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {manager} from "../../common/administrator/managerMixin";
    import {Loading} from "element-ui";
    import {uploadFile} from "../../network/manager/commonRequest";
    import {getJiliGames, updateJiliGames} from "../../network/manager/jiliGamesRequest";
    import sportsConst from "../../common/sportsConst";

    export default {
        name: "ManagerJiliGames",
        mixins: [manager],
        components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                activeNames: 0,
                activeName2: 'a1',
                search:{},
                gameList: [],
                imageUrl: '',
                actionUrl: '/upload_image_3',
                fileList: [],
                file: null,
            }
        },
        methods: {
            getJiliGamesList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getJiliGames(this.search, 1, 10000).then(res => {

                    this.gameList = res.data.data
                    this.gameList.map(game => {
                        this.$set(game, 'imageUrlTemp', game.imgurl)
                        game.imgurl = null
                    })
                    loadingInstance.close()
                })
            },
            updateJiliGame(item) {
                this.$confirm('[' + item.enname + '] 을 업데이트 하시겠습니까?', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    updateJiliGames(item).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '업데이트 완료되였습니다'
                            });
                            this.getJiliGamesList()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 2000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                });
            },

            handleChange(val) {
                //console.log(val);
            },
            async submitUpload(game) {
                if (this.imgChange) {
                    // 如果上传的文件改变了，执行文件上传方法获取新的地址
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    uploadFile(this.actionUrl, this.file).then(res => {
                        if (res.data.success) {
                            let uploadedFileName = res.data.data.uploadedFileName;
                            let fullpath = res.data.data.fullpath;
                            game.imgurl = uploadedFileName
                            updateJiliGames(game).then(res => {
                                loadingInstance.close()
                                this.getJiliGamesList()
                            })
                        } else {
                            loadingInstance.close()
                        }
                    })
                }
                // 执行新增操作 / 修改操作
            },
            // 覆盖默认行为
            httpRequest() {
                this.imgChange = true;
            },
            fileChanged(file, fileList) {
                this.file = file.raw;
            },
            // 上传之前
            beforeUpload(file) {

            },
        },
        created() {
            this.getJiliGamesList()
        },
        watch: {}
    }
</script>

<style scoped>

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 18px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }

    .avatar {
        width: 120px;
        height: 120px;
        display: block;
    }

    .jiligames-item{
        width: 23.3%;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #c1a2a2;
        border: 1px solid #f5f5f5;
        margin-top: 10px;
        text-align: center;
        padding: 10px 0;
        border-radius: 10px;
    }
    .jiligames-item div{
        width: 100%;
    }

</style>
import httpManager from "../axios/httpManager";

export function getJiliBetlist(search, pageNum, pageSize) {
    let url = '/jiligames/jili_bet_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime();
    return httpManager.post(url, search);
}
export function getJiliGames(search, pageNum, pageSize) {
    let url = '/jiligames/jili_games_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime();
    return httpManager.post(url, search);
}
export function updateJiliGames(update) {
    let url = '/jiligames/jili_games_update' + '?t=' + new Date().getTime();
    return httpManager.post(url, update);
}



export function gettHonorCasinoAccountBalance(userId) {
    return httpManager.get('/casino/honor/get_account_balance?userId=' + userId + '&t=' + new Date().getTime())
}

export function subjecttHonorCasinoMemberCasinoCash(userId) {
    return httpManager.get('/casino/honor/subject_member_casino_cash?userId=' + userId + '&t=' + new Date().getTime())
}
export function sysJiliGamesData(time) {
    return httpManager.get('/casino/honor/syns_data?time=' + time + '&t=' + new Date().getTime())
}
